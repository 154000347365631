<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="flex w-full">
        <!-- Bảng 1: Bên trái -->
        <div class="w-1/2 pr-4">
          <div class="relative flex flex-col min-w-0 break-words shadow-lg rounded bg-white p-5">
            <div class="rounded-t mb-4 py-3 border-0">
              <div class="flex flex-wrap items-center justify-between">
                <div class="relative px-4 max-w-full">
                  <h3 class="font-semibold text-lg text-blueGray-700">
                    Thông tin giao dịch
                  </h3>
                </div>
              </div>
            </div>
            <div class="block w-full overflow-x-auto relative">
              <table v-if="transaction" class="items-center w-full bg-transparent border-collapse list-config">
                <thead>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Mã giao dịch
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.payment_no }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Mã Yêu cầu
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs  bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.title }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Thời gian khởi tạo
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.created_at }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Thời gian cập nhật
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.updated_at }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Tên Merchant
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.merchant_key }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Giá trị giao dịch
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.amount }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Phí xử lý giao dịch
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    -
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Phí thanh toán
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    -
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Trạng thái
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.status.label }}
                  </th>
                </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>

        <!-- Bảng 2: Bên phải -->
        <div class="w-1/2 pl-4">
          <div class="relative flex flex-col min-w-0 break-words shadow-lg rounded bg-white p-5">
            <div class="rounded-t mb-4 py-3 border-0">
              <div class="flex flex-wrap items-center justify-between">
                <div class="relative px-4 max-w-full">
                  <h3 class="font-semibold text-lg text-blueGray-700">
                    Thông tin thanh toán
                  </h3>
                </div>
              </div>
            </div>
            <div class="block w-full overflow-x-auto relative">
              <table class="items-center w-full bg-transparent border-collapse list-config">
                <thead>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Đối tượng
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Merchant
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Tên NH/TGTT
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.meta_data.card_brand }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    IP
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.meta_data.client_ip }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Nguồn tiền
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    -
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Phương thức thanh toán
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{changeNameMethod(transaction.meta_data.method)}}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Ngân hàng phát hành
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.meta_data.card_brand }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Số thẻ/STK
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ maskedCardNumber(transaction.meta_data.card_number) }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Số thẻ mã hóa
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    -
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Tên chủ thẻ
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    {{ transaction.meta_data.cardholder_name }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Phí xử lý giao dịch
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    -
                  </th>
                </tr>
                <tr>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    Phí thanh toán
                  </th>
                  <th class="text-center width10 px-6 align-middle border py-3 text-xs uppercase bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                    -
                  </th>
                </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.list-config .wrap_service_type_labels {
  display: flex;
  grid-column-gap: 8px;
  flex-wrap: wrap;
  grid-row-gap: 7px;
}
</style>

<script>

import apiClient from "@/services/api";
import {toast} from "vue3-toastify";

// const debounceFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      transaction: {
        status: {
          label: '',
          value: ''
        },
        meta_data: {
          client_ip: '',
          method: '',
          card_brand: '',
          invoice_no: '',
          card_number : '',
          cardholder_name: ''
        }
      }
    };
  },

  props: {
    //
  },
  async created() {
    try {
      let {data} = await apiClient.get('api/transaction/detail/' + this.$route.params.id);
      if (data) {
        this.transaction = data.data;
        this.transaction.meta_data = JSON.parse(this.transaction.meta_data);
        return
      }
      toast("Đã có lỗi xảy ra!", {
        "type": "error",
        "transition": "flip",
        "dangerouslyHTMLString": true
      })
    } catch (e) {
      toast("Đã có lỗi xảy ra!", {
        "type": "error",
        "transition": "flip",
        "dangerouslyHTMLString": true
      })
      console.log(e);
    }
  },
  watch: {
    //
  },
  methods: {
    maskedCardNumber(cardNumber) {
      if (!cardNumber) return '';
      return cardNumber.replace(/^(\d{6})\d*(\d{4})$/, '$1xxxxxx$2');
    },
    changeNameMethod(method) {
      const methodMapping = {
        WALLET: 'Ví điện tử',
        ATM_CARD: 'Thẻ nội địa',
        COLLECTION: 'Thu hộ',
        CREDIT_CARD: 'Thẻ quốc tế'
      };

      return methodMapping[method] || method;
    },
  },
};
</script>
