<template>
  <div>
    <Notify :showNotice = "showNotice" :messErr = "messErr" :messageNotice="messageNotice"></Notify>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
        :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <!-- Hàng chứa tiêu đề, ô tìm kiếm và nút "Thêm mới" -->
          <div class="flex flex-wrap items-center justify-between">
            <!-- Tiêu đề -->
            <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
              Quản lý thông tin merchant
            </h3>

            <!-- Nút "Thêm mới" -->
            <button
                class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button" @click="toggleModal('create', '')"
            >
              <i class="fas fa-plus"></i> Thêm mới
            </button>
          </div>
          <div class="bg-white block w-full overflow-x-auto flex-auto py-10 pt-0 mt-6">
            <form>
              <div class="flex flex-wrap items-center">
                <!-- Ô nhập Tên Merchant -->
                <div class="w-full lg:w-3/12 px-4 mb-4">
                  <div class="relative w-full">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Tên merchant
                    </label>
                    <multiselect
                        v-model="formData.merchant_key"
                        :options="merchantOptions"
                        placeholder="Vui lòng nhập..."
                        :searchable="true"
                        :loading="merchantLoading"
                        :options-limit="50"
                        open-direction="bottom"
                        track-by="name"
                        label="name"
                        @search-change="searchMerchant"
                    ></multiselect>
                  </div>
                </div>

                <div class="w-full lg:w-3/12 px-4 mb-4 lg:ml-8">
                  <div class="relative w-full">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Từ ngày
                    </label>
                    <input
                        type="date"
                        lang="vi"
                        v-model="formData.date_from"
                        class="border-0 px-3 mt-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>

                <div class="w-full lg:w-3/12 px-4 mb-4 lg:ml-8">
                  <div class="relative w-full">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Đến ngày
                    </label>
                    <input
                        type="date"
                        lang="vi"
                        v-model="formData.date_to"
                        class="border-0 px-3 mt-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div class="w-full flex justify-end mt-3">
                <button @click="handleSearch"
                        class="hover:bg-emerald-600 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button">
                  <i class="fas fa-search"></i> Tìm kiếm
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="block w-full overflow-x-auto">
          <!-- Projects table -->
          <table class="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  class=" width10 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  STT
                </th>
                <th
                  class=" width10 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Tên merchant
                </th>
                <th
                  class=" width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Status
                </th>
                <th
                    class=" width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  ngày tao Merchant
                </th>
                <th
                  class=" width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Merchant Key
                </th>

                <th
                  class="width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Merchant Secret Key
                  <span v-if="dataSecretKey == true" @click="hiddenColumn()" style="cursor: pointer;"><i
                      class="fa fa-eye-slash" style="font-size: 18px;"></i></span>
                  <span v-if="dataSecretKey == false" @click="showColumn()" style="cursor: pointer;"><i class="fas fa-eye"
                      style="font-size: 18px;"></i></span>
                </th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataTable" :key="index">
                <th
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span class="ml-3 font-bold" :class="[
                    color === 'light' ? 'text-blueGray-600' : 'text-white',
                  ]">
                    {{ index + 1 }}
                  </span>
                </th>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ item.name }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <span v-if="item.status == 1"><i class="fas fa-circle text-emerald-500 mr-2"></i> Hoạt động</span>
                  <span v-if="item.status == 0"><i class="fas fa-circle text-orange-500 mr-2"></i> Ngừng hoạt động</span>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ new Date(item.created_at).toISOString().replace('T', ' ').slice(0, 16) }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ item.merchant_key }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ item.merchant_secret_key }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <button
                    class="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-on:click="toggleModal('create', item.id)">
                    <i class="fas fa-edit" style="font-size: 18px;"></i>
                  </button>
                  <button
                    class="text-red-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-on:click="deleteData(item.id)">
                    <i class="fas fa-trash" style="font-size: 18px;"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot style="height: 50px;">
              <Panigation :dataPage="dataUser" @pageChange:index="fetchData" />
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div>
      <form-merchant v-if="showModal" :activeForm="showModal" @update:activeForm="updateActiveForm" :typeForm="form"
        :idData="itemId">
      </form-merchant>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.main-boss {
  display: grid;
  grid-template-rows: 0fr;
}

.overlay-1 {
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.overlay-2 {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
</style>
<script>
import Panigation from "@/components/Panigation.vue";
import formMerchant from './formMerchant.vue';
import apiClient from '@/services/api.js';

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import Notify from "@/components/Notification/Notify.vue"
import { toast } from 'vue3-toastify';
import Multiselect from "vue-multiselect";
import {debounce} from "vue-debounce";

const debounceFn = debounce(callback => callback(), '400ms')


export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      showModal: false,
      dataTable: [],
      dataUser: [],
      merchant_name: '',
      merchant_key: null,
      merchantOptions: [],
      merchantLoading: false,
      form: '',
      formData: {
        merchant_key: null,
        date_to: '',
        date_from: ''
      },
      itemId: '',
      progress: 0, // Giả sử bạn có một biến progress để theo dõi tiến trình
      limit: 10,
      page: null,
      dataSecretKey: true,
      messageNotice: '',
      messErr: false,
      showNotice: false,
    };
  },
  components: {
    Multiselect,
    formMerchant,
    Panigation,
    Notify
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchData();
    this.searchMerchant();
  },
  methods: {
    // Call api xóa dữ liệu bảng
    async deleteData(id) {
      if (confirm("Bạn có chắc xóa thông tin này không?")) {
        await apiClient.delete('cms/merchant-authent/delete/' + id)
          .then(response => {
            if (response.data.error_code == 1) {
              this.showNotice = true;
              this.messageNotice = 'Update thất bại. Vui lòng thử lại sau!';
              this.messErr = true;
              toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
            } else {
              this.messageNotice = response.data.message;
              this.showNotice = true;
            }
            setTimeout(() => {
                this.showNotice = false;
              }, 2000);
            this.fetchData();
          })
          .catch(error => {
            toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
              "type": "error",
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
            console.error('Đã có lỗi xảy ra!', error);
          });
      }

    },
    toggleModal(value, id) {
      this.form = value;
      this.itemId = id;
      this.showModal = !this.showModal;
      if (this.showModal) {
        document.getElementById("main-content").classList.add("overlay-1");
      }
    },

    updateActiveForm(value) {
      this.showModal = value;
      if (!this.showModal) {
        document.getElementById("main-content").classList.remove("overlay-1");
      }
    },

    fetchData(page = 1) {
      if (page) this.page = page;
      apiClient.get('api/cms/merchant-authent/list', {
        params: {
          page: this.page,
          name: this.formData.merchant_key?.merchant_key,
          date_from: this.formData.date_from,
          date_to: this.formData.date_to,
        }
      })
        .then(response => {
          this.dataTable = response.data.data;
          this.dataUser = response.data;
        })
        .catch(error => {
          toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          console.error('Đã có lỗi xảy ra!', error);
        });
    },

    hiddenColumn() {
      console.log(this.dataTable);
      this.dataSecretKey = false;
      if (!this.dataSecretKey) {
        this.dataTable.forEach(item => {
          item.temp_merchant_secret_key = item.merchant_secret_key;
          item.merchant_secret_key = item.merchant_secret_key.replace(/./g, '*');
        });
      }
    },

    showColumn() {
      this.dataSecretKey = true;
      if (this.dataSecretKey) {
        this.dataTable.forEach(item => {
          // Thực hiện thay thế dấu '*' bằng chuỗi ban đầu
          item.merchant_secret_key = item.temp_merchant_secret_key;
        });
      }
    },
    handleSearch() {
      this.fetchData();
    },
    searchMerchant(query) {
      debounceFn(async () => {
        // if (!query || query.trim() === "") {
        //   return;
        // }
        this.merchantLoading = true;
        const self = this;
        try {
          let {data} = await apiClient.get('api/merchant/search', {
            params: {keyword: query}
          })
          self.merchantLoading = false;
          if (data.status) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
      })
    },
  },
};
</script>
